import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: {
        // dark: true,
        themes: {
            light: {
                primary: '#35b3d1',
                secondary: '#35b3d1',
                accent: '#FFA69E',
                background: '#F0F0F0',
                error: '#FF686B',
                info: '#3DA470',
                success: '#35b3d1',
                warning: '#FF686B',
                footer:'#303030',
                price:'#F0F0F0',
            },
            dark: {
                primary: '#35b3d1',
                secondary: '#35b3d1',
                accent: '#FFA69E',
                background: '#303030',
                error: '#FF686B',
                info: '#F8E16C',
                success: '#35b3d1',
                warning: '#FF686B',
                footer:'#1C1C1C',
                price:'#F0F0F0',
            }
        },
        options: { customProperties: true },
    },
})
