<template>
  <v-container>

    <!-- ---------------------------------------------------- ORGAS -->

    <v-row>
      <v-col
          cols="12"
          sm="6"
          v-for="orga in orgas"
          :key="orga.id"
      >
        <orga-card
            :orga="orga"
            @click="select_orga(orga)"
            elevation="10"
            class='ma-5'
            color="primary"
            :style="{
                        background: $vuetify.theme.dark ? $vuetify.theme.themes.dark.footer : '#e6e6e6'
                    }"
        >
          <v-alert
              type="success"
              v-if="contact_video_sent[orga.id]"
              width="100%"
          >{{$t('contacts.sentmessage')}}</v-alert>
          <v-btn
              v-else
              @click.stop="contacting_orga = orga"
              color="primary"
              text
          >
            <v-icon left>mdi-email</v-icon>{{$t('contacts.contact')}}
          </v-btn>
        </orga-card>
      </v-col>
    </v-row>

    <!-- ---------------------------------------------------- CONTACT FORM -->

    <v-card
        :disabled="contacting"
        class='pa-5 m-3'
        v-if="contacting_orga"
        :style="{
                        background: $vuetify.theme.dark ? $vuetify.theme.themes.dark.footer : '#e6e6e6'
                    }"
    >
      <v-card-title>{{$t('contacts.contact')}} {{contacting_orga.name}}</v-card-title>
      <v-form
          v-model="contact_form_is_valid"
          ref="contact_form"
          :style="{
                        background: $vuetify.theme.dark ? $vuetify.theme.themes.dark.footer : '#e6e6e6'
                    }"
      >
                <span
                    v-for="(info,name) in contact_form"
                    :key="name"
                >
                    <v-textarea
                        v-if="info.textarea"
                        :label="name"
                        @keydown="contact_error=null"
                        :rules="[
                            info.required !== false ? rules.required:null,
                            info.link=='email' ? rules.email : null
                        ].filter(e=>e)
                    "
                        v-model="contact_data[info.link]"
                    ></v-textarea>
                    <v-text-field
                        v-else
                        :label="name"
                        @keydown="contact_error=null"
                        :rules="[
                            info.required !== false ? rules.required:null,
                            info.link=='email' ? rules.email : null
                        ].filter(e=>e)
                    "
                        v-model="contact_data[info.link]"
                    ></v-text-field>
                </span>
      </v-form>
      <v-alert
          type="error"
          v-if="contact_error"
      >{{contact_error}}</v-alert>
      <v-btn
          :disabled="!contact_form_is_valid || contacting"
          :loading="contacting"
          color="primary"
          @click="send_contact"
      >{{$t('contacts.send')}}</v-btn>
      <v-btn
          color="primary"
          text
          @click="contacting_orga=null"
      >{{$t('cancel')}}</v-btn>
    </v-card>

    <!-- ---------------------------------------------------- MAP -->

    <div class='contact_map mt-7'>
      <l-map
          class='map'
          ref='map'
          :zoom="zoom"
          :center="center"
          @move="moved"
          @zoom="moved"
      >
        <l-tile-layer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            class='tt'
        >
        </l-tile-layer>
        <span
            v-for="marker in markers"
            :key="marker.orga_id"
        >
                    <l-marker
                        :lat-lng="marker.geo"
                        :icon="get_icon(marker.icon)"
                    >

                        <l-tooltip
                            :content="marker.infos"
                            :options="{ direction:'left' }"
                        ></l-tooltip>
                    </l-marker>
                </span>
      </l-map>
    </div>
  </v-container>
</template>

<script>

import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet"
import { icon } from 'leaflet'
import orgaCard from '../components/orga-card.vue'

/*
@desc Contacts Page
*/
export default {
  name: 'Contacts',
  components: { LMap, LTileLayer, LMarker, orgaCard, LTooltip },
  data: () => ({
    orgas: [],
    center: { lat: 45.761538515060735, lng: 4.918220454397045 },
    zoom: 15,
    icons_map: {},
    to: null,

    contact_video_sent: {},
    contact_error: null,

    contact_form_is_valid: true,
    contacting_orga: null,
    contact_data: {
      email: null,
      subject: null,
      body: null
    },
    contacting: false,
  }),
  computed: {
    /*
    @desc Creates organization map markers (geoloc + icons)
    */
    markers() {
      return this.orgas.map(orga => ({
        geo: orga.meta,
        icon: '/images/pins/orga.png',
        orga_id: orga.id,
        infos: this.$t('contacts.orgamapinfo', {organame: orga.name, orgaaddress: orga.address, orgaphoneNumber: orga.phoneNumber})
      }))
    },

    rules() {
      return {
        required: v => !!v || this.$t('requiredfield'),
        email: v => {
          if (!v) return false
          return !!v.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g) || 'E-mail incorrect'
        }
      }
    },

    contact_form() {
      return {
        [this.$t('email')]: {link: 'email'},
        [this.$t('contacts.subject')]: {link: 'subject'},
        [this.$t('contacts.message')]: {link: 'body', textarea: true},
      }
    },

    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  methods: {
    /*
    @desc Triggered when map is moved by user
    @arg1 map event
    */
    moved(evt) {
      clearTimeout(this.to)
      this.to = setTimeout(() => {
        this.center = evt.target.getCenter()
        this.zoom = evt.target.getZoom()
      }, 100)
    },
    /*
    @desc Creates and returns singleton icon map objects
    @arg1 image url
    */
    get_icon(url) {
      if (!this.icons_map[url]) {
        this.icons_map[url] = icon({
          iconUrl: url,
          iconSize: [64, 64],
          iconAnchor: [16, 37]
        })
      }
      setTimeout(() => {
        this.icons_map[url].options.iconSize = [100, 100]
        this.icons_map[url].createIcon()
      }, 1000)
      return this.icons_map[url]
    },
    /*
    @desc Select specified organization
    */
    select_orga(orga) {
      console.log(orga.meta)
      this.center = orga.meta
      this.zoom = 17
    },
    /*
    @desc Send mail to orga
    */
    async send_contact() {
      try {
        const contact_body = { ...this.contact_data }
        const orga_id = this.contacting_orga.id
        this.contacting = true
        await this.$utils.wait(3000)
        await this.$api.user.organizations.contact(orga_id, contact_body)
        this.contacting = false
        this.$refs.contact_form.reset()
        this.contacting_orga = null
        this.$set(this.contact_video_sent, orga_id, true)
      } catch (e) {
        this.contact_error = this.$t('contacts.sendingerror')
        this.contacting = false
      }
    }
  },
  /*
  @desc Load website organizations
  */
  mounted() {
    this.$api.user.organizations.get_all().then(orgas => this.orgas = orgas)
  }
}
</script>

<style scoped>
.contact_map {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
}
</style>